import { SectionCommonProps } from 'components/styles/Section/types';

export enum HeroDivisionMode {
  Default = 'default',
  Wide = 'wide',
  Half = 'half',
  SingleCentered = 'singleCentered',
}
export interface HeroBaseProps extends SectionCommonProps {
  title?: string;
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  description?: ReactComponentReturnType;
  subtitle?: string;
  actions?: ReactComponentReturnType;
  divisionMode?: HeroDivisionMode;
  isCentered?: boolean;
  withOverlay?: boolean;
  fitHeightTo?: 'content' | 'image';
}
