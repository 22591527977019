import React, { Fragment } from 'react';
import { Box, Flex } from 'theme-ui';

import Embed from 'components/molecules/Videos/Embed';
import { LinkButton } from 'components/atoms/Buttons/Button';
import Image from 'components/atoms/Image';
import HeroBase from '../HeroBase';
import { HeroWithMediaProps } from './types';
import { StyledHeroWithMediaContent } from './styles';
import { HeroDivisionMode } from '../HeroBase/types';
import { getButtonsVariant } from './utils';

const HeroWithMedia: React.FC<HeroWithMediaProps> = ({
  title,
  description,
  subtitle,
  media,
  links = [],
  isContentNarrow,
  divisionMode,
  buttonsAlignment,
  children,
  ...props
}) => (
  <HeroBase
    {...{ title, description, subtitle, ...props }}
    divisionMode={media ? divisionMode : HeroDivisionMode.Wide}
    actions={
      <Flex variant={getButtonsVariant(buttonsAlignment)}>
        {links.map(({ key, content, ...linkProps }) => (
          <LinkButton {...{ key }} {...linkProps}>
            {content}
          </LinkButton>
        ))}
      </Flex>
    }
  >
    {media && (
      <Fragment>
        {media?.type === 'video' && (
          <Embed
            src={media.src}
            placeholderSrc={media.placeholderSrc}
            alt={media.alt}
            width={444}
            height={250}
            ratio={444 / 250}
            placeholderLoading="eager"
          />
        )}
        {media?.type === 'image' && (
          <Fragment>
            <Box
              variant="hero.withImage.image"
              sx={{ display: media.mobile ? ['none', '', 'block'] : 'auto' }}
            >
              <Image
                src={media.src}
                alt={media.alt}
                width={media.width}
                height={media.height}
              />
            </Box>
            {media.mobile && (
              <Box variant="hero.withImage.imageMobile">
                <Image
                  src={media.mobile.src}
                  alt={media.mobile.alt}
                  width={media.mobile.width}
                  height={media.mobile.height}
                />
              </Box>
            )}
          </Fragment>
        )}
      </Fragment>
    )}
    {children && (
      <StyledHeroWithMediaContent {...{ isContentNarrow }}>
        {children}
      </StyledHeroWithMediaContent>
    )}
  </HeroBase>
);

export default HeroWithMedia;
