import { HeroWithMediaProps } from './types';

export const getButtonsVariant = (
  buttonsAlignment: HeroWithMediaProps['buttonsAlignment'],
): string => {
  switch (buttonsAlignment) {
    case 'left':
      return 'hero.buttons';
    case 'center':
      return 'hero.buttonsCentered';
    case 'right':
      return 'hero.buttonsRight';

    default:
      return 'hero.buttons';
  }
};
